import React, { useState, useContext, useEffect } from "react";
import FilteredProduct from "./filteredProduct";
import axios from "axios";
import { Spinner } from "@chakra-ui/react";
import toast from "react-hot-toast";
import { AuthContext } from "../auth/authProvider";
import SelectableGrid from "./common/selectableGrid";
import { useMediaQuery } from "react-responsive";

const GridViewProduct = ({ categories, selectedCategoryId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const { user, token } = useContext(AuthContext);

  const fetchProductData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/product/getAll`,
        { userId: user.id },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const sortedProducts = response.data.data.sort((a, b) => a.pos - b.pos);
      setProducts(sortedProducts);
    } catch (error) {
      toast.error("Failed to fetch products");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProductData();
  }, []);

  useEffect(() => {
    if (selectedCategoryId) {
      setFilteredProducts(
        products
          .filter((product) => product.categoryId === selectedCategoryId)
          .filter((product) => !product.isDisabled)
      );
    } else {
      setFilteredProducts(products.filter((product) => !product.isDisabled));
    }
  }, [selectedCategoryId, products]);

  const isXs = useMediaQuery({ maxWidth: 575 });
  const isSm = useMediaQuery({ minWidth: 576, maxWidth: 1025 });
  const isMd = useMediaQuery({ minWidth: 1026, maxWidth: 1199 });
  const isLg = useMediaQuery({ minWidth: 1200, maxWidth: 1277 });
  const isXl = useMediaQuery({ minWidth: 1278, maxWidth: 1615 });

  const getCols = () => {
    if (isXs) return 1;
    if (isSm) return 1;
    if (isMd) return 2;
    if (isLg) return 2;
    if (isXl) return 3;
    return 4;
  };
  
  return (
    <div className="custom-scrollbar overflow-x-hidden h-full hide-scrollbar">
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner color="#FF8943" size="xl" />
        </div>
      ) : (
        <SelectableGrid cols={getCols()} items={filteredProducts}>
          {({ onClick, isSelected, item }) => (
            <FilteredProduct
              onClick={onClick}
              isSelected={isSelected}
              item={item}
            />
          )}
        </SelectableGrid>
      )}
    </div>
  );
};

export default GridViewProduct;
